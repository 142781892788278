<template>
  <v-container fluid tag="section">
    <v-layout align-top>
      <v-card width="400" height="450" class="mx-auto mt-12">
        <v-card-title class="justify-center mt-10 mb-10">
          <span class="text-h2">{{ $t('login-title') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="account"
              prepend-icon="mdi-account-circle"
              @keyup.enter="loginAccount"
              :placeholder="$t('account.account')"
              tabindex="1"
            />
            <v-text-field
              v-model="password"
              :type="showPassword ? 'text' : 'password'"
              prepend-icon="mdi-lock"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              @keyup.enter="loginAccount"
              :placeholder="$t('account.password')"
              tabindex="2"
            />
            <!-- <v-text-field
              v-model="otp"
              :type="showOtp ? 'text' : 'password'"
              prepend-icon="mdi-google"
              :append-icon="showOtp ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showOtp = !showOtp"
              @keyup.enter="loginAccount"
              placeholder="Google OTP"
              tabindex="3"
            /> -->
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn color="info" class="text-h4 mr-0" @click="loginAccount">登入</v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "login",
  data() {
    return {
      showPassword: false,
      showOtp: false,
      account: "",
      password: "",
      // otp: ""
    };
  },
  computed: {
    ...mapState(["token"])
  },
  methods: {
    ...mapActions(["submitData"]),
    loginAccount() {
      if (this.account.length > 0 && this.password.length > 0) {
        let url = process.env.VUE_APP_SERVER_URL + "/api/token/";
        console.log(
          " process.env.VUE_APP_SERVER_URL" + process.env.VUE_APP_SERVER_URL
        );
        let currentObj = this;
        this.axios
          .post(url, {
            username: this.account,
            password: this.password,
            // otp: this.otp
          })
          .then(function(response) {
            // console.log(response.data);
            let info = JSON.parse(JSON.stringify(response.data));
            if ("access" in info) {
              currentObj.submitData({
                user: info["user"],
                token: info["access"],
                role: info["role"],
                language: info["language"]==1?'zhHant':'zhHans',
              });
              if (info["language"]==2) {
                currentObj.$i18n.locale = 'zhHans'
              } else {
                currentObj.$i18n.locale = 'zhHant'
              }
              var is_account_admin = info["role"]==1?true:false
              currentObj.toHome(info["role"]);
            } else {
              console.log("Fail to login");
              alert(this.$i18n.t("login-failure.password"));
            }
          })
          .catch(function(error) {
            console.log(error);
            if (error.response) {
              let info = JSON.parse(JSON.stringify(error.response.data));
              if ("non_field_errors" in info) {
                alert(this.$i18n.t("login-failure.password"));
              } else if ("detail" in info) {
                console.log(info)
                alert(info['detail'])
              }
            } else {
              alert(this.$i18n.t("login-failure.network"));
            }
          });
      } else {
        alert(this.$i18n.t("login-failure.other"));
      }
    },
    toHome(role) {
      let url = "";
      if (role===1) {
        url = "AccountList";
      } else if (role===10) {
        url = "CustomerContainerBoxList";
      }else {
        url = "KontainerBoxList";
      }
      this.$router.push({
        name: url,
        params: {
          // item: item
        }
      });
    }
  },
  mounted() {
    this.$store.commit("SET_DRAWER", false);
  }
};
</script>

<style lang="sass" scoped>
.container
  height: 100%
.layout
  height: 100%
</style>
